export const BASE_API_URL = process.env.REACT_APP_BASE_URL;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const API_LOGIN_URL = `/users`;

export const FONT_FAMILY_GENERAL = "Noto Sans Display";
export const COLOR_PRIMARY = "#0099CC";
export const COLOR_SECONDARY = "#FFF";
export const COLOR_VARIANT = "#7ABA57";
export const COLOR_GREY = "rgb(128, 128, 128)";
export const COLOR_ERROR = "#ff0033";
export const COLOR_PRIMARY_LIGHT = "#F5FBFC";
export const COLOR_LINK = "rgb(0, 153, 204)";
export const TEXT_COLOR_PRIMARY = "#000";
export const HEADER_HEIGHT = 71; // 72 but 1 is removed as there is a border to this header
export const FOOTER_HEIGHT = "28px";
export const SIDENAV_WIDTH = "20%";
// export const API_URL = "https://staging.api.hemehealth.app/lab/v1"; // staging
// export const API_URL = "https://preprod.api.hemehealth.app/lab/v1"; // preprod
export const API_URL = "https://staging.api.hemehealth.app/corporate/v1";
export const COOKIE_EXPIRY_DAYS = 1826;
export const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE;

export const API_ENDPOINT = {
  GENERATE_OTP: `${BASE_API_URL}/auth/otp/generate`,
  VERIFY_OTP: `${BASE_API_URL}/auth/otp/verify`,
  LOGOUT: `${BASE_API_URL}/auth/logout`,
  COMPANY_PLAN_DETAILS: `${BASE_API_URL}/companies/plan-details`,
  SCHEDULED_CAMPS: `${BASE_API_URL}/camps/upcoming/count`,
  SERVICE_PLAN: `${BASE_API_URL}/services`,
  EMPLOYEE: `${BASE_API_URL}/employees`,
  UPCOMING_CAPMS: `${BASE_API_URL}/camps/upcoming`,
};

export const SIZE = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1023px",
  laptopL: "1439px",
  desktop: "2560px",
};

export const DEVICE = {
  mobileS: `(max-width: ${SIZE.mobileS})`,
  mobileM: `(max-width: ${SIZE.mobileM})`,
  mobileL: `(max-width: ${SIZE.mobileL})`,
  tablet: `(max-width: ${SIZE.tablet})`,
  laptop: `(max-width: ${SIZE.laptop})`,
  laptopL: `(max-width: ${SIZE.laptopL})`,
  desktop: `(max-width: ${SIZE.desktop})`,
  desktopL: `(max-width: ${SIZE.desktop})`,
};
