import { createAsyncThunk } from "@reduxjs/toolkit";
import { getBasicStats, getScheduledCamps } from "../../api";

export const getBasicStatsAction = createAsyncThunk(
  "basic-stats",
  async (_, thunkAPI) => {
    try {
      const response = await getBasicStats();
      return response;
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(
        error || "Error while fetching basic stats"
      );
    }
  }
);

export const getScheduledCampsActions = createAsyncThunk(
  "scheduled-camps",
  async (_, thunkAPI) => {
    try {
      const response = await getScheduledCamps();
      return response;
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(
        error || "Error while fetching scheduled camps"
      );
    }
  }
);
