import { API_ENDPOINT } from "../utilities/constants";
import { apiClient } from "./api-client";

const generateOTP = async (data) => {
  try {
    const response = await apiClient.post(
      API_ENDPOINT.GENERATE_OTP,
      data,
      true
    );
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const verifyOTP = async (data) => {
  try {
    const response = await apiClient.post(API_ENDPOINT.VERIFY_OTP, data, true);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const signout = async () => {
  try {
    const response = await apiClient.get(API_ENDPOINT.LOGOUT);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export { generateOTP, signout, verifyOTP };
