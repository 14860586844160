import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addEmployee,
  deleteEmployeeById,
  getEmployeeByNumber,
  getEmployees,
  getTotalActiveEmployeesCount,
  getTotalEmployeesCount,
  updateEmployeeById,
} from "../../api";
import { setShowStatus, unsetShowStatus } from "../slice/status-slice";

export const addEmployeeAction = createAsyncThunk(
  "add-employee",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Adding employee...",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await addEmployee(data);
      thunkAPI.dispatch(
        setShowStatus({
          message: "Employee added successfully.",
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while adding employee",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while adding employee");
    }
  }
);

export const getTotalEmployeesCountAction = createAsyncThunk(
  "total-employee",
  async (_, thunkAPI) => {
    try {
      const response = await getTotalEmployeesCount();
      return response;
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(
        error || "Error while fetching total employees count"
      );
    }
  }
);

export const getTotalActiveEmployeesCountAction = createAsyncThunk(
  "total-active-employee",
  async (_, thunkAPI) => {
    try {
      const response = await getTotalActiveEmployeesCount();
      return response;
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(
        error || "Error while fetching total active employees count"
      );
    }
  }
);

export const getEmployeesAction = createAsyncThunk(
  "get-employees",
  async (count, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching employees",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getEmployees(count);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching employees",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while fetching employees"
      );
    }
  }
);

export const getEmployeeByNumberAction = createAsyncThunk(
  "get-employee-by-number",
  async (payload, thunkAPI) => {
    try {
      const response = await getEmployeeByNumber(payload);
      return response;
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(error || "Error while fetching employee");
    }
  }
);

export const deleteEmployeeByIdAction = createAsyncThunk(
  "delete-employee-by-id",
  async (employeeId, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Deleting employees",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await deleteEmployeeById(employeeId);
      thunkAPI.dispatch(
        setShowStatus({
          message: "Employee deleted successfully",
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while deleting employee",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while deleting employee");
    }
  }
);

export const updateEmployeeByIdAction = createAsyncThunk(
  "update-employee-by-id",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Updating employees",
          severity: "info",
          autoHide: "no",
        })
      );
      const { employeeId, name, email, number } = data;
      const response = await updateEmployeeById(employeeId, {
        name: name,
        email: email,
        number: number,
      });
      thunkAPI.dispatch(
        setShowStatus({
          message: "Employee updated successfully",
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while updating employee",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while updating employee");
    }
  }
);
