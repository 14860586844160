import { API_ENDPOINT } from "../utilities/constants";
import { apiClient } from "./api-client";

const getUpcomingCamps = async () => {
  try {
    const response = await apiClient.get(API_ENDPOINT.UPCOMING_CAPMS);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export { getUpcomingCamps };
